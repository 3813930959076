.mb-3 {
  margin-bottom: 0rem !important;
}
/* .bg-body-tertiary {
  background-color: transparent !important;
} */
.justify-content-end {
  justify-content: center !important;
}
.pe-3 {
  padding-right: 0rem !important;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 5rem !important;
}

@media screen and (max-width: 600px) {
  .offcanvas-title img {
    display: none;
  }
}


.navbar {
  --bs-navbar-padding-y: 1rem !important;
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: none !important;
}
.nav-link:focus,
.nav-link:hover {
  color: #d6bd83 !important;
  font-weight: 800 !important ;
}
a:hover {
  --bs-link-color-rgb: #d6bd83 !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #d6bd83 !important;
  font-weight: 700 !important ;
}
.nav-link {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 19.07px;
  text-align: center;
  color: #d6bd83;
}

/* Ensure the Navbar Toggle Button is Visible */
.navbar-toggler {
  display: block !important; /* Force visibility */
  border: 2px solid #d6bd83 !important; /* Add a visible border */
  background-color: white !important; /* Ensure a white background */
  padding: 8px 12px; /* Improve button spacing */
  z-index: 1050 !important; /* Ensure it's above other elements */
}

/* Ensure the three-bar icon inside the button is visible */
.navbar-toggler-icon {
  filter: invert(0) !important; /* Keep the icon dark and visible */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(214, 189, 131, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  width: 30px !important;
  height: 30px !important;
}

/* Hide Navbar Toggle Button on Larger Screens */
@media screen and (min-width: 992px) {
  .navbar-toggler {
    display: none !important; /* Hide toggle button on larger screens */
  }
}

/* Ensure the Navbar Toggle Button is Visible on Mobile */
@media screen and (max-width: 991px) {
  .navbar-toggler {
    display: block !important; /* Show only on mobile screens */
    border: 2px solid #d6bd83 !important; /* Add a visible border */
    background-color: white !important; /* Ensure a white background */
    padding: 8px 12px; /* Improve button spacing */
    z-index: 1050 !important; /* Ensure it's above other elements */
  }

  /* Ensure the three-bar icon inside the button is visible */
  .navbar-toggler-icon {
    filter: invert(0) !important; /* Keep the icon dark and visible */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(214, 189, 131, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
    width: 30px !important;
    height: 30px !important;
  }
}
