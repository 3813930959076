.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 9999;
  }
  
  .modalContent {
    background: rgba(0, 0, 0, 0.6);
    padding: 40px;
    /* border-radius: 10px; */
    width: 90%;
    max-width: 800px;
    height: 500px; /* Adjusted height */
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Ensures content is centered vertically */
    position: relative;
  }
  
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    height: 50px; /* Adjust size */
    position: absolute;
    top: 15px;
    left: 15px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .title {
    font-size: 35px;
    font-weight: bold;
    margin: 20px 0 10px;
    
  }
  
  .description {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    position: absolute;
    bottom: 40px;
  }
  
  .emailInput {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
  }
  
  .subscribeButton {
    background-color: #d6bd83; /* Gold-like button */
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .subscribeButton:hover {
    background-color: #d6bd83;
  }
  