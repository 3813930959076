.navvBtn {
  width: 128px;
  height: 38px;
  border-radius: 4px;
  background: rgba(185, 142, 53, 1);
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 17.7px;
  text-align: center;
  border: 0;
  color: rgba(255, 255, 255, 1);
}
