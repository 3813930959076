.carsMainHeading {
  padding-left: 30px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding-top: 360px;
  padding-bottom: 190px;
}

.carsHeading {
  width: 628px;
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.carsText {
  width: 559px;
  padding-top: 5px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

/* Search Css  */

.searchContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 45px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -140px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  border-radius: 23px;
  box-shadow: 0px 4px 30.3px 0px rgba(0, 0, 0, 0.09);
}

.select1Main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.searchSelect {
  width: 377px;
  height: 45px;
  border-radius: 10px;
  background: rgba(244, 246, 249, 1);
  border: 0;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  padding: 10px;
  color: rgba(51, 50, 50, 1);
}

.searchBtnMain {
  padding-top: 20px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.searchBtn {
  width: 163px;
  height: 44.35px;
  border-radius: 4.44px;
  background-color: #d6bd83;
  /* background: rgba(185, 142, 53, 1); */
  border: 0;
  font-family: Open Sans;
  font-size: 15.52px;
  font-weight: 700;
  line-height: 21.14px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.inputPriceMain {
  display: flex;
  align-items: center;
}

.priceInput1 {
  width: 80px;
  height: 40px;
  background: rgba(244, 246, 249, 1);
  border: 0;
}

.priceInput2 {
  margin-left: 2px;
  width: 250px;
  height: 40px;
  background: rgba(244, 246, 249, 1);
  border: 0;
}

.btninput {
  width: 46px;
  height: 40px;
  border: 0;
  border-radius: 0px 4px 4px 0px;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  background-color: #d6bd83;
  /* background: rgba(185, 142, 53, 1); */
}

/* Industry css  */

.indusMain {
  padding-top: 40px;
  padding-bottom: 30px;
}

.indusContainer {
  background-image: url("../../Images/indusBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 594px; */
}

.indusHeadingMain {
  padding-top: 280px;
  padding-bottom: 90px;
  padding-left: 60px;
}

.indusHeading {
  width: 501px;
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.indusText {
  padding-top: 5px;
  width: 508px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

/* Feedback css  */

/* .feedbackMain{
    background-color: rgba(210, 205, 205);

} */
.feedbackHeading {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 54.47px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.feedbackSliderMain {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.sliderContainer {
  height: 306.46px;
  width: 431.77px;
  padding: 25.23px;
  border-radius: 4.21px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 16.1px 0px rgba(0, 0, 0, 0.07);
}

.imgRateMain {
  display: flex;
  justify-content: space-between;
}

.SliderHeading {
  padding-top: 10px;
  font-family: Open Sans;
  font-size: 23.13px;
  font-weight: 600;
  line-height: 31.5px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(19, 50, 64, 1);
}

.SliderText {
  padding-top: 10px;
  font-family: Open Sans;
  font-size: 16.82px;
  font-weight: 400;
  line-height: 22.91px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(19, 50, 64, 1);
}

@media screen and (max-width: 948px) {
  .searchBtnMain {
    justify-content: center;
    align-items: center;
  }
  .select1Main {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 728px) {
  .searchSelect {
    width: 297px;
  }
  .priceInput1 {
    width: 60px;
  }
  .priceInput2 {
    width: 190px;
  }

  .carsText {
    width: auto;
  }

  .carsHeading {
    font-size: 20px;
    width: auto;
    line-height: 1.5;
  }

  .indusHeading {
    width: auto;
    font-size: 20px;
    line-height: 1.5;
  }

  .indusHeadingMain {
    padding: 120px 20px;
  }

  .sliderContainer {
    height: auto;
    width: auto;
  }
}
