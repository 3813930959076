/* Change the default dot color */
.slick-dots li button:before {
  color: rgba(222, 195, 142, 0.3);
  font-size: 10px;
}
.slick-dots {
  background-color: transparent !important;
}
.slick-dots li {
  margin: 0 0px;
}
/* color: rgba(185, 142, 53, 1); */
.slick-dots li.slick-active button:before {
  color:#d6bd83;
  font-size: 10px;
}

.slick-dots {
  position: relative;
  top: -90px !important;
}

body,
html {
  overflow-x: hidden;
}

.btn-close {
  background-color: #fff !important;
  color: white !important; /* Override the default color */
  background-color: transparent !important; /* Remove any background color */
}

@media screen and (max-width: 600px) {
  .nav-link {
    color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    margin: 10px 0;
  }
}
